const serverTimeModule = {
    namespaced: true,
    state: {
        serverTimeObject: JSON.parse(localStorage.getItem("serverTimeObject"))
    },
    mutations: {
        setServerTimeMutation(state, serverTimeObj) {
            state.serverTimeObject = serverTimeObj;
            localStorage.setItem("serverTimeObject", JSON.stringify(state.serverTimeObject));
        },
        clearServerTimeMutation(state) {
            state.serverTimeObject = null;
            localStorage.removeItem('serverTimeObject');
        },
    },
    actions: {
        setServerTime({ commit }, serverTimeObj) {
            commit('setServerTimeMutation', serverTimeObj);
        },
        clearServerTime({commit}) {
            commit('clearServerTimeMutation');
        },
    },
    getters: {
        getServerTimeObject:(state)=>{
            //console.log(state);
            if (!state.serverTimeObject){
                return null;
            }
            return state.serverTimeObject;
        }
    }
};

export default serverTimeModule;





